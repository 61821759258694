import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=ce09bfa8&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.shop.hva/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BFormGroup, BButton, BInputGroupAppend, BInputGroup, BCol, BRow, BForm, BCard, BAlert} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BFormGroup, BButton, BInputGroupAppend, BInputGroup, BCol, BRow, BForm, BCard, BAlert})
    

export default component.exports